import 'reflect-metadata';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './plugins/i18n'
import { alertKey, AlertStoreFactory } from './composables/alert';
import { tippyKey, TippyStoreFactory } from './composables/tippies';
import Amplify, { Storage } from 'aws-amplify'
import awsExports from './aws-exports'
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';
import { captureException, init, setTag } from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import MTransOnlineUI from 'mtrans-online-ui';
import { createPinia } from 'pinia';

const saveTokensToSeamlessLogin = () => {  
  const tokensValue = `${new URLSearchParams(window.location.search).get(`tokens`)}`;
  const [appID, user, idToken, accessToken] = [...tokensValue.split('|')];
  if(user && appID && idToken && accessToken){
    const head = "CognitoIdentityServiceProvider";
    const idTokenKey = `${head}.${appID}.${user}.idToken`
    const accessTokenKey = `${head}.${appID}.${user}.accessToken`
    const lastAuthUserKey = `${head}.${appID}.LastAuthUser`
    localStorage.setItem(idTokenKey, idToken);
    localStorage.setItem(accessTokenKey, accessToken);
    localStorage.setItem(lastAuthUserKey, user);
    window.location.href = window.location.href.replace(`tokens=${tokensValue}`, '');
  }
}
saveTokensToSeamlessLogin();

const alertStore = new AlertStoreFactory().create();
const tippyStore = new TippyStoreFactory().create();

const app = createApp(App)
.use(router)
.use(i18n)
.use(MTransOnlineUI)
.use(createPinia())
.provide(alertKey, alertStore)
.provide(tippyKey, tippyStore);

// Start Sentry
// https://github.com/getsentry/sentry-javascript/issues/2925
app.config.errorHandler = (error, _, info) => {
  setTag('info', info);
  captureException(error);
};
init({ 
  dsn: 'https://591cc705b48c4e71a4f4d6c6ec3a30fb@o562017.ingest.sentry.io/5700205',
  environment: process.env.VUE_APP_STAGE,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.2,  
});
// End Sentry

app.mount('#app');

const configureAmplify = () => {
  // clientid
  const qpClientId = new URLSearchParams(window.location.search).get(`${process.env.VUE_APP_KEY_CLIENT_ID}`);
  if(qpClientId){
    awsExports.Auth.userPoolWebClientId = qpClientId;
    awsExports.Auth.oauth.redirectSignIn = `${process.env.VUE_APP_AWS_AUTH_OAUTH_RedirectSignIn}/?${process.env.VUE_APP_KEY_CLIENT_ID}=${qpClientId}`;
    localStorage.setItem(`${process.env.VUE_APP_KEY_CLIENT_ID}`, qpClientId);
  } else {
    const lsClientId = localStorage.getItem(`${process.env.VUE_APP_KEY_CLIENT_ID}`);
    if(lsClientId){
      awsExports.Auth.userPoolWebClientId = lsClientId;
      awsExports.Auth.oauth.redirectSignIn = `${process.env.VUE_APP_AWS_AUTH_OAUTH_RedirectSignIn}/?${process.env.VUE_APP_KEY_CLIENT_ID}=${lsClientId}`;
    }
  }
  Amplify.configure(awsExports);
  applyPolyfills().then(() => {
    defineCustomElements(window);
  });
  Storage.configure({
      customPrefix: {
        public: '',
    },
  })
}

configureAmplify();